.form-holder {
  @include exp-prop(transform, translate3d(0, 0, 0));
}
.is-shaking {
  @include exp-prop(animation-duration, 0.8s);
  @include exp-prop(animation-fill-mode, both);
  @include exp-prop(animation-name, shake);
}
.MuiTab-textColorInherit.Mui-selected {
    background: gainsboro;
}
.MuiTab-wrapper, .MuiTypography-body1 {
    text-align: left;
    text-transform: none;
}
.MuiTab-wrapper h1, .MuiTypography-body1 h1{
    font-weight: bold;
}
.section-desc {
	margin-bottom: 10px;
}

.MuiBox-root {
    padding: 40px 24px !important;
}

button#vertical-tab-0 {
    opacity: unset !important;
}

.headertab, .headertab h1 {
    background-color: #5078C0;
    color: white;
}
.tabpanel-header {
	background-color: #5078C0;
  color: white;
  text-align: center;
  padding: 6px;
}
.tabpanel {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    border-bottom: 40px solid white;
}
@media (min-width: 320px) and (max-width: 480px) {
  .MuiBox-root {
    padding: 0px 5px !important;
  }
  .tabpanel {
    border-bottom: unset;
  }
}