.login-nav{
    background-color: #284993 !important;
}

.forget-pass-text{
    color: #4C69BA !important;
}

.forget-pass-text:hover{
    color: #1D356B !important;
}

.warning-bg{
    background-color: #DACE19 !important;
}
.white-bg{
    background-color: white !important;
}

.transparent-bg{
    background-color: #00B7E0 !important;
}

.footer-bg{
background-color: #1E3970 !important;
}

.contact-title{
    color: #363636 !important;
}

.privacy-term-title{
    color: #4A4A4A  !important;
}

.google-bg{
    background-color: #ff0000 !important;
}

.navigation-links{
    color: white !important;
    background-color: transparent !important;
}

.navigation-links:hover{
    color: wheat !important;
    background-color: transparent !important;;
}

.ham-burger{
    color: red;
    background-color: red !important;
}

.navbar-menu{
    background-color:  #00B7E0 !important;
    color: black !important;
    
}

.box-shadow{
    -webkit-box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.75) !important;
    -moz-box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.75) !important;
    box-shadow: 0px 0px 15px -8px rgba(0,0,0,0.75) !important;
}

.primary-bg{
    background-color: #2ED47A !important;
}

.text-black{
    color: #363636 !important;
}

.text-white{
    color: white !important;
}
.dashboard-button{
    border: 1px solid transparent !important;
}

.dashboard-button:hover{
    border: 1px solid darkgray !important;
}

.rounded{
    border-radius: 3px !important;
}

.border-none{
    border: none !important;;
}

/* .textarea{
    border: 1px solid rgba(169, 169, 169, 0.418) !important;
} */

input{
    color: black !important;
    border: 1px solid rgba(169, 169, 169, 0.418) !important;
    background-color: transparent !important;
}

.textarea{
    color: black !important;
    border: 1px solid rgba(169, 169, 169, 0.418) !important;
    background-color: transparent !important;
}

.input::placeholder{
    color: darkgray !important;
}
.input:focus{
    border:  none !important;
    outline: 2px solid #00B7E0 !important;
}
.textarea::placeholder{
    color: darkgray !important;
}

.textarea:focus{
    border:  none !important;
    outline: 2px solid #00B7E0 !important;
}
.text-blue{
    color: #00B7E0 !important;
}

.white-text{
    color: white !important;
}

.message-body{
    border-radius: 3px !important;
    background-color: #caffe1 !important;
    border-left: 4px solid #2ED47A !important;
    border-bottom: none !important;
    border-right: none !important;
    border-top: none !important;
}

.answer-btn{
    height: 60px !important;
    border: 1px solid darkgray !important;
    border-radius: 3px !important;
}

.selected-answer{
    background-color: #2ACB74 !important;
}

.exercise-item{
    background-color: white !important;
    
}

.exercise-item:hover{
    background-color: rgb(218, 218, 218) !important;
}

.blue-bg{
    background-color: #00B7E0 !important;
}

/* Mobile devices (devices smaller than 768px) */
@media only screen and (max-width: 767px) {
    
}
  
  /* Tablet devices (devices between 768px and 1024px) */
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .charecter-mbl{
        display: none !important;
    }
}
  
  /* Desktop devices (devices larger than 1024px) */
  @media only screen and (min-width: 1025px) {
    .charecter-mbl{
        display: none !important;
    }
}