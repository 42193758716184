body {
  font-family: "Roboto" !important;
}

.panel {
  background: #fff !important;
  border-radius: $radius !important;
  border: none !important;
  box-shadow: 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.1),
    0 0 0 0.0625rem rgba(0, 0, 0, 0.1) !important;
  .panel-heading {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    background: #fff !important;
    .field {
      margin: 0 !important;
    }
    .subtitle {
      font-weight: 400 !important;
      color: #4A4A54 !important;
    }
  }
  .panel-block {
    border: none  !important;
    padding: 0.9375rem 1.25rem 1.25rem 1.25rem !important;
    display: block  !important;
  }
}

.has-facebook-bg {
  background-color: $facebook-color;
}

.fallsWeb-loader {
  .hero-body {
    position: relative;
    &:after {
      @include loader();
      border-left-color: black !important;
      border-bottom-color: red !important;
      position: absolute !important;
      width: 2rem;
      height: 2rem;
      left: calc(50% - (2rem / 2));
      top: calc(50% - (2rem / 2));
    }
  }
  &.is-large-loader {
    .hero-body:after {
      width: 6rem;
      height: 6rem;
    }
  }
}

// .clickable-div {
//   :hover {
//     box-shadow: 10px 10px black !important;
//   }
// }

// .footer {
//   position: absolute;
//   bottom: 0;
//   width: 100%;
// }

.has-wordwrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  white-space: normal;
}

.has-frame-border {
  video {
    border: 0.2rem solid $info;
    border-radius: 4px;
  }
}

.progress-has-value {
  z-index: 10;
  text-align: center;
  display: inherit;
  position: relative;
  top: 22px;
}

progress {
  position: relative;
  &::after {
    content: attr(title);
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: calc(1rem / 1.5);
    line-height: 1rem;
  }
}

progress.show-value.is-medium:after {
  font-size: calc(1.25rem / 1.5);
  line-height: 1.25rem;
  color: black;
  font-weight: 600;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid $primary;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.custom-invisiable-style-wider {
  @media only screen and (min-width: 1280px) {
    display: none;
  }
}

//Solution for bulma footer ref component : helpers/HeaderFooterWrapper.js
.main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main-content {
  flex: 1;
  padding-bottom: 150px;
  padding-top: 50px;
}

.has-2wayview-bg {
  background-color: $twowayView-logo-bg;
}
.has-2wayview-color {
  color: $twowayView-logo-color;
}

.steps {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
}

.main-content {
  background: linear-gradient(180deg, $primary 0%, $info 100%);
}

.footer {
  background-color: #1e3970;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

.fulltabs {
  width: 100% !important;
  li {
    width: 50% !important;
  }
}

.has-sign-up {
  margin-top: -100px !important;
  padding-top: 110px !important;
  margin-bottom: 0.5rem !important;
}
.card-has-custom-width {
  z-index: 2;
  width: 75%;
  margin-left: 12% !important;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5), 0px 4px 25px rgba(0, 0, 0, 0.12) !important;
  border-radius: 13px !important;
}

.tabs {
  a {
    align-items: center !important;
    border-bottom-color: $success !important;
    border-bottom-style: solid !important;
    border-bottom-width: 4px !important;
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 1px !important;
    padding: 0.5em 0em !important;
    vertical-align: top !important;
  }
}

.tabs{
  a:hover{
    border-bottom-color: #363636 !important;
  }
}

.tabs-dashboard {
  @extend .tabs;
  a {
    border-bottom-color: $success !important;
  }
}

.is-128x128-custom {
  width: 128px;
}

.main-content-dashboard {
  @extend .main-content;
  background: #00b7e0 ;
}

.footer-dashboard {
  @extend .footer;
  background-color: #0089a8;
  padding-top: 5px;
  padding-bottom: 5px !important;
}

.has-border-white {
  border-color: white !important;
}

.card-has-shadow {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5), 0px 4px 25px rgba(0, 0, 0, 0.12) !important;
  border-radius: 3px !important;
}
.box-report {
  margin: -25px !important;
}
.has-bottom-0 {
  margin-bottom: 0px !important;
}

.level-dashboard {
  @extend .level;
  @extend .has-bottom-0;
}
.has-space {
  margin-left: 25px;
}
.progress.is-small {
  height: 0.4rem;
}
.box-has-outline {
  @extend.box;
  border: 2px solid $primary;
  box-shadow: 0px 4px 4px rgba(0, 183, 224, 0.2);
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}
.box-has-outline-black {
  @extend.box;
  border: 1px solid $primary;
  box-shadow: 0px 4px 4px rgba(34, 224, 0, 0.2);
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.has-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: 50vh;
}

/* width */
.has-scroll::-webkit-scrollbar {
  width: 0.5em;
}

.has-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.has-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.is-ghost {
  background-color: #00B7E0 !important;
  border: navajowhite;
  color: white !important;;
}

.is-ghost:hover{
  background-color: #00abd1 !important;
}
.is-previous {
  top: 10px !important;
  left: 12px !important;
}
.is-next {
  top: 10px !important;
  right: 12px !important;
}
.navbar-item img {
  max-height: 2.75rem !important; 
  width: 210px !important;
  height: 44px !important;
}

.is-clickable_has-default-poiner {
  @extend .is-ghost;
  cursor: default !important;
}
.is-clickable_has-cursor-pointer {
  @extend .is-ghost;
  cursor: pointer !important;
}
// .is-ghost {
//   cursor: pointer !important;
// }

//feedback
.pulsating {
  animation: pulsating 1.5s ease-in-out infinite;
}

@keyframes pulsating {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

