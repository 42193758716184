//Primary colors
$info: #284993;
$primary: #00b7e0;
$danger: #ff0000;
$success: #2ed47a;
$warning: #dace19;

$badge-font-size: 0.65rem !default;
$badge-height: 14px !default;
$badge-padding: 0.05rem 0.15rem !default;
$badge-shadow: 0 0 0 2px 0 !default;
$scheme-main: 0 0 0 2px 0 !default;
$dark: #111;
$white: #ffffff;
//Link colors
$link: $primary;

//add social media colors
$facebook-color: #39579a;

//add 2 wayview color
$twowayView-logo-bg: #B9C9EF;
$twowayView-logo-color: #6c6418;
//override bulma variables
$footer-padding: 2rem 1.5rem 1rem;

//metrics
$header_height: 50px;
$footer_height: 20px;
$tabs_height: 49px;
$left_sidebar_width: 100px;
$right_sidebar_width: 360px;

//easings
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeOutCustom: cubic-bezier(0.1, 0.82, 0.25, 1);

// Modal minimal setup
$transition-duration: 0.3s;
$transition-duration-newsPaper: 0.7s;
$transition-duration-3dslit: 0.5s;

$modal-perspective: 1300px;
$modal-bg-color: rgba($dark, 0.86);
