@mixin absolute() {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

@mixin v-center {
  position: relative;
  top: 50%;
  display: inline-block;
  @include exp-prop(transform, translateY(-50%));
}

@mixin lin-grad($from, $to: darken($from, 13.5%)) {
  background: $from;
  @include exp-value(background, linear-gradient($from, $to));
}

@mixin transform($props...) {
  @include exp-prop(transform, $props...);
}
@mixin transform-origin($props...) {
  @include exp-prop(transform-origin, $props...);
}
@mixin transition($props...) {
  @include exp-prop(transition, $props...);
}
@mixin transition-duration($props...) {
  @include exp-prop(transition-duration, $props...);
}
@mixin transition-timing-function($props...) {
  @include exp-prop(transition-timing-function, $props...);
}
@mixin transition-delay($props...) {
  @include exp-prop(transition-delay, $props...);
}

$_prefixes: (-webkit, -moz, -ms, -o);
@mixin exp-prop($prop, $val...) {
  @each $prefix in $_prefixes {
    #{$prefix}-#{$prop}: $val;
  }
  #{$prop}: $val;
}
@mixin exp-value($prop, $val) {
  @each $prefix in $_prefixes {
    #{$prop}: #{$prefix}-#{$val};
  }
  #{$prop}: #{$val};
}

@mixin badge-positions {
  &.is-left {
    bottom: 0;
    left: 0;
    right: auto;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &.is-right {
    bottom: auto;
    left: auto;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
  }
  &.is-top {
    bottom: auto;
    left: 50%;
    right: auto;
    top: 0;
    transform: translate(-50%, -50%);
  }
  &.is-top-left {
    bottom: auto;
    left: 0;
    right: auto;
    top: 0;
    transform: translate(-50%, -50%);
  }
  &.is-top-right {
    bottom: auto;
    left: auto;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
  }
  &.is-bottom {
    bottom: 0;
    left: 50%;
    right: auto;
    top: auto;
    transform: translate(-50%, 50%);
  }
  &.is-bottom-left {
    bottom: 0;
    left: 0;
    right: auto;
    top: auto;
    transform: translate(-50%, 50%);
  }
  &.is-bottom-right {
    bottom: 0;
    left: auto;
    right: 0;
    top: auto;
    transform: translate(50%, 50%);
  }
}
.badge {
  background-color: $primary;
  border: 2px solid transparent;
  border-radius: $badge-height;
  box-shadow: $badge-shadow;
  color: $scheme-main;
  font-size: $badge-font-size;
  height: $badge-height;
  line-height: calc(#{$badge-height / 2} + 1px);
  min-width: $badge-height;
  overflow: hidden;
  padding: $badge-padding;
  position: absolute;
  right: 0;
  text-overflow: ellipsis;
  top: 0;
  transform: translate(50%, -50%);
  white-space: nowrap;

  &.is-outlined {
    background-color: $white;
    border-color: $primary;
    color: $primary;
  }
  @include badge-positions;

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      &:not(.is-outlined) {
        background-color: $color;
        color: $color-invert !important;
      }
      &.is-outlined {
        border-color: $color;
        color: $color;
      }
      // If light and dark colors are provided
      @if length($pair) >= 4 {
        $color-light: nth($pair, 3);
        $color-dark: nth($pair, 4);
        &.is-light {
          color: $color-dark;
          &:not(.is-outlined) {
            background-color: $color-light;
          }
          &.is-outlined {
            border-color: $color;
          }
          // Tabs position fix
        }
      }
    }
  }
}
.tabs {
  li {
    position: relative;
  }
}
